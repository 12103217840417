// import { CONTACT_TAB, ROUTES } from "../../constants.js"
import React, { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import ChevronLeft from "~/images/icons/chevron-left.svg"
import ChevronRight from "~/images/icons/chevron-right.svg"
import Img from "../helpers/img"
import Join from "../components/join/join.js"
import Layout from "../components/layout/layout"
import SEO from "../components/seo.js"
import ScrollAnimation from "react-animate-on-scroll"
import StoreMap from "../components/store-map/store-map.js"
import TitleHeader from "../components/title-header/title-header.js"
import { graphql } from "gatsby"
import useWindowSize from "../helpers/hooks/useWindowSize.js"

const Locator = ({ data, pageContext, location }) => {
  const breakPoint = 1240
  const listRef = useRef()
  let itemRefs = []
  const [listPosition, setListPosition] = useState(1)
  const [activeItem, setActiveItem] = useState(1)
  const { isMobile, width } = useWindowSize()

  const seo = pageContext.page.seo
  const {
    pageContent: {
      stores_content: { reseller, topPage },
    },
  } = data

  const handleClick = i => {
    const destIndex =
      i < 0
        ? 0
        : i > data.gammes.nodes.length - 1
        ? data.gammes.nodes.length - 1
        : i
    setActiveItem(destIndex)
    let scrollTo = 0
    if (!!listRef.current && !!itemRefs[destIndex].current) {
      if (!isMobile) {
        scrollTo =
          -itemRefs[destIndex].current.offsetLeft +
          listRef.current.offsetWidth / 2 -
          itemRefs[destIndex].current.offsetWidth / 2 -
          itemRefs[destIndex].current.offsetWidth +
          52
      } else {
        scrollTo = -itemRefs[destIndex].current.offsetWidth * destIndex
      }
    }
    setListPosition(scrollTo)
  }

  useEffect(() => {
    handleClick(activeItem)
    isMobile ? setActiveItem(0) : setActiveItem(1)
  }, [width, listRef])

  return (
    <Layout location={location}>
      <SEO meta={seo} />
      <div className={"container"}>
        <div className={"page-locator"}>
          <TitleHeader title={topPage.titre} paragraph={topPage.description} />
          <h2 className={"ranges-title"}>
            Les grandes enseignes où retrouver nos produits :
          </h2>

          <ScrollAnimation animateIn="fadeInBottom" duration={1}>
            <div className="ranges">
              {width && width < breakPoint ? (
                <Swiper
                  className="range-list"
                  tag="ul"
                  slidesPerView={2.15}
                  spaceBetween={16}
                  onSlideChange={({ activeIndex }) =>
                    setActiveItem(activeIndex)
                  }
                >
                  {!!data.gammes &&
                    !!data.gammes.nodes &&
                    data.gammes.nodes.map(
                      (
                        {
                          name,
                          gamme_content: { productImage, color = "#FFFFFF" },
                        },
                        i
                      ) => {
                        return (
                          <SwiperSlide
                            tag="li"
                            key={i}
                            className={`item ${
                              activeItem === i ? "active" : ""
                            }`}
                            onClick={() => handleClick(i)}
                          >
                            <InnerBlock
                              image={productImage}
                              name={name}
                              color={color}
                            />
                          </SwiperSlide>
                        )
                      }
                    )}
                </Swiper>
              ) : (
                <>
                  <button
                    className="ranges-prev"
                    onClick={() => handleClick(activeItem - 1)}
                  >
                    <img src={ChevronLeft} alt="" />
                  </button>
                  <button
                    className="ranges-next"
                    onClick={() => handleClick(activeItem + 1)}
                  >
                    <img src={ChevronRight} alt="" />
                  </button>
                  <ul className="range-list" ref={listRef}>
                    {!!data.gammes &&
                      !!data.gammes.nodes &&
                      data.gammes.nodes.map(
                        (
                          {
                            name,
                            gamme_content: { productImage, color = "#FFFFFF" },
                          },
                          i
                        ) => {
                          const newRef = React.createRef()
                          itemRefs.push(newRef)
                          return (
                            <li
                              style={{
                                transform: `translateX(${listPosition}px)`,
                              }}
                              className={`item ${
                                activeItem === i ? "active" : ""
                              }`}
                              key={i}
                              ref={newRef}
                              onClick={() => handleClick(i)}
                              onKeyDown={() => handleClick(i)}
                              aria-hidden
                            >
                              <InnerBlock
                                image={productImage}
                                name={name}
                                alt={"Funky Veggie " + name}
                                color={color}
                              />
                            </li>
                          )
                        }
                      )}
                  </ul>
                </>
              )}
            </div>
          </ScrollAnimation>

          {!!data &&
            !!data.gammes &&
            !!data.gammes.nodes[activeItem] &&
            !!data.gammes.nodes[activeItem].gamme_content && (
              <ScrollAnimation animateIn="fadeInBottom" duration={1}>
                <div className="stores">
                  <h2>Liens vers leurs magasins&nbsp;:</h2>
                  {width && width < breakPoint ? (
                    <Swiper
                      className="store-list"
                      tag="ul"
                      slidesPerView={2.5}
                      spaceBetween={24}
                    >
                      {!!data.gammes.nodes[activeItem].gamme_content &&
                        !!data.gammes.nodes[activeItem].gamme_content
                          .enseignes &&
                        data.gammes.nodes[
                          activeItem
                        ].gamme_content.enseignes.map(
                          (
                            {
                              featuredImage = null,
                              title = "",
                              enseigne_content = null,
                            },
                            i
                          ) => {
                            return (
                              <SwiperSlide tag="li" key={i} className="item">
                                <a
                                  target="_blank"
                                  href={enseigne_content.linkStores}
                                  rel="noreferrer"
                                >
                                  {!!featuredImage && !!featuredImage.node && (
                                    <InnerBlock image={featuredImage} />
                                  )}
                                  {title && <p className="title">{title}</p>}
                                </a>
                              </SwiperSlide>
                            )
                          }
                        )}
                      {!data.gammes.nodes[activeItem].gamme_content
                        .enseignes && (
                        <div className="online">
                          Uniquement en ligne ! Arrive bientôt en magasin...
                        </div>
                      )}
                    </Swiper>
                  ) : (
                    <ul className="store-list">
                      {!!data.gammes.nodes[activeItem].gamme_content &&
                        !!data.gammes.nodes[activeItem].gamme_content
                          .enseignes &&
                        data.gammes.nodes[
                          activeItem
                        ].gamme_content.enseignes.map(
                          (
                            {
                              featuredImage = null,
                              title = "",
                              enseigne_content = null,
                            },
                            i
                          ) => {
                            return (
                              <li
                                key={i}
                                className="item fadeInTop"
                                style={{
                                  animationDelay: `${0.1 * i}s`,
                                  animationDuration: "0.5s",
                                  opacity: 0,
                                  animationFillMode: "forwards",
                                }}
                              >
                                <a
                                  target="_blank"
                                  href={enseigne_content.linkStores}
                                  rel="noreferrer"
                                >
                                  {!!featuredImage && !!featuredImage.node && (
                                    <InnerBlock
                                      image={featuredImage}
                                      alt={
                                        featuredImage?.node?.altText
                                          ? featuredImage?.node?.altText
                                          : title
                                      }
                                    />
                                  )}
                                  {title && <p className="title">{title}</p>}
                                </a>
                              </li>
                            )
                          }
                        )}
                      {!data.gammes.nodes[activeItem].gamme_content
                        .enseignes && (
                        <div className="online">
                          Uniquement en ligne ! Arrive bientôt en magasin...
                        </div>
                      )}
                    </ul>
                  )}
                </div>
              </ScrollAnimation>
            )}
        </div>
        {/* <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <StoreMap {...data.stores} />
        </ScrollAnimation> */}
      </div>

      <Join
        title={reseller.titre}
        text={reseller.text}
        buttonTitle={reseller.buttonTitle}
        buttonLink={`${reseller.buttonLink.uri}#2`}
        // buttonLink={`${ROUTES.CONTACT}${CONTACT_TAB.SELL}`}
      />
    </Layout>
  )
}

const InnerBlock = ({ image, name = "", color = "#FFFFFF", alt = "" }) => {
  return (
    <div
      className={`inner`}
      style={{
        backgroundColor: color,
      }}
    >
      {!!image && (
        <Img
          source={
            !!image && !!image.node ? image.node.sourceUrl : image.sourceUrl
          }
          classe="image"
          width={256}
          alt={image?.node ? image.node.altText || alt : image.altText || alt}
        />
      )}
      {name && <p className="name">{name}</p>}
    </div>
  )
}

export default Locator

export const query = graphql`
  query LocatorQuery($id: String!) {
    pageContent: wpPage(id: { eq: $id }) {
      stores_content {
        reseller {
          buttonLink {
            ... on WpPage {
              uri
              title
              slug
            }
          }
          buttonTitle
          text
          titre
        }
        topPage {
          description
          titre
        }
      }
    }
    gammes: allWpProductRange(filter: { slug: { nin: ["packs", "goodies"] } }) {
      nodes {
        slug
        name
        gamme_content {
          color
          enseignes {
            ... on WpEnseigne {
              featuredImage {
                node {
                  sourceUrl
                  srcSet
                }
              }
              title
              enseigne_content {
                linkStores
              }
            }
          }
          productImage {
            sourceUrl
            altText
          }
        }
      }
    }
    stores: allWpIndependentStore {
      nodes {
        store_content {
          description
          phone
          website
          address {
            city
            country
            countryShort
            latitude
            longitude
            placeId
            postCode
            state
            streetAddress
            stateShort
            streetName
            streetNumber
            zoom
          }
          fieldGroupName
        }
        title
      }
    }
  }
`
